<template>
    <div class="my-6">
        <h3 class="font-bold">List of {{ route.name }}</h3>
        <span class="text-sm text-gray-500">Here you can observe all items related to the status of projects.
        </span>
    </div>
    <div class="flex mb-6 justify-end">
        <router-link to="/status/create">
            <Button color="primary" :text="`Create new Status`" />
        </router-link>
    </div>
    <div class="card">
        <DataTable :headers="headers" :data="data" :options="options" color="primary" />
    </div>
</template>
  
<script>
import DataTable from "../../components/Elements/Datatable.vue";

import { inject, onMounted, ref } from "vue";
import { GET, DELETE } from "../../services/api";
import { useRoute, useRouter } from "vue-router";
import Button from "../../components/Elements/Button.vue";

export default {
    name: "controlers",
    components: {
        DataTable,
        Button,
    },
    setup() {
        const headers = [
            { key: "Id", value: "id" },
            { key: "Name", value: "name" },
            { key: "Options", value: "" },
        ];

        const route = useRoute();
        const router = useRouter();
        const modal = inject("modal");
        const alert = inject("alert");
        const loader = inject("loading");
        const data = ref([]);

        onMounted(async () => {
            try {
                loader.open();
                const status = await GET("status");

                data.value = status.sort((a, b) => a.id < b.id ? -1 : (a.id > b.id ? 1 : 0))

                loader.close();
            } catch (e) {
                loader.close();
                if (e.includes("Token") || e.includes("t_dynamik") || e == "Unauthorized") {
                    localStorage.removeItem("t_dynamik");
                    localStorage.removeItem("info_dynamik");
                    router.push({ path: '/login' });
                }
                alert.open("Error", e, "danger");
            }
        });


        const deleteItem = (status) => {
            loader.open();
            const deleteFunction = async function () {
                try {
                    loader.open();
                    await DELETE(`status/${status.id}`);
                    data.value = data.value.filter((item) => item.id != status.id);
                    loader.close();
                    alert.open(
                        "Success!",
                        `Status was deleted successfully!`,
                        "success"
                    );
                } catch (e) {
                    loader.close();
                    if (
                        e.includes("Token") ||
                        e.includes("t_dynamik") ||
                        e == "Unauthorized"
                    ) {
                        localStorage.removeItem("t_dynamik");
                        localStorage.removeItem("info_dynamik");
                        router.push({ path: "/login" });
                    }
                    alert.open("Error", e, "danger");
                }
            };
            modal.open(
                "Attention",
                "Do you really wish to delete this Status?",
                "warning",
                "Yes",
                () => deleteFunction(),
                modal.close()
            );
            loader.close();
        };


        // const show = (r) => {
        //   router.push(`/parceiros/editar/${r.id}`);
        // };

        const options = [
            { icon: "pen", path: "/status/edit", title: "Edit Status" },
            {
                icon: "trash",
                action: (param) => deleteItem(param),
                title: "Delete Status",
            },
        ];

        return { headers, data, options, route };
    },
};
</script>
  
<style></style>